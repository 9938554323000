@import 'antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme('borderColor.DEFAULT', currentColor); /* 2 */
}

::before,
::after {
  --tw-content: '';
}

@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html {
  overflow-y: scroll;
} */

body.ant-modal-open-cs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll
}

.ant-menu-root {
  border-inline-end: none !important;
}

.ant-divider-inner-text {
  padding-inline: 0 !important;
}

.bg-mask-overlay {
  background: #eeeeee1d !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.rotate-infinite {
  animation: rotate 1s linear infinite;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 32px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
   
    width: 30%;
    max-width: 350px;
    padding: 0;
  }
  .dndflow aside.left-sidebar {
    width: 20%;
    max-width: 300px;
    padding: 15px;
  }
}

.ant-form-item{
  margin-bottom: 14px !important;
}
.ant-btn-icon{
  vertical-align: -0.175em !important;
}
.mask-image-scroll{
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
.mask-image-scroll:hover{
  -webkit-mask-position: left top;
}

/*Loader*/
.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  -webkit-animation: dot-pulse2 1.5s ease-in-out infinite;
  animation: dot-pulse2 1.5s ease-in-out infinite;
}

.dot-1 {
  background-color: #4285f4;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-2 {
  background-color: #34a853;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.dot-3 {
  background-color: #fbbc05;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.dot-4 {
  background-color: #ea4335;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.dot-5 {
  background-color: #4285f4;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes dot-pulse2 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
}
